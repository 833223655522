import React, { useEffect } from "react";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import { pageTitle } from "../../helper";
import Hero8 from "../Hero/Hero8";
import MovingText from "../MovingText";
import Cta from "../CtaTwoButtons";

import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";

import {
  MediaCommunitySkin,
  MediaOutlet,
  MediaPlayer,
  MediaPoster,
} from "@vidstack/react";

export default function Videos() {
  pageTitle("Home");

  function VideoPlayer({ src, poster }) {
    return (
      <MediaPlayer
        src={src}
        poster={poster}
        aspectRatio={16 / 9}
        className="yt-lite"
      >
        <MediaOutlet>
          <MediaPoster />
        </MediaOutlet>
        <MediaCommunitySkin />
      </MediaPlayer>
    );
  }

  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);

  const buttonLink =
    "https://cb.universalfranchising.com.br/materiais/apresentacao.pdf";
  const buttonLink2 = "https://chat.whatsapp.com/I6ZjvBRu7HZ0tXPKSGyuq7";

  return (
    <>
      {/* Start Hero Section */}
      <Hero8
        title="Veja um pouco das nossas participações em alguns podcasts"
        subtitle="Separamos alguns videos para que você possa assistir algumas participações em podcasts que fomos convidados, acompanhe:"
        btnText="Assistir"
        btnLink="#next"
        btnTarget="_self"
        btnVariant="contained"
        scrollDownId="#next"
        bgImageUrl="/images/bg/bg-cb-1.jpg"
        bgImageUrlWebp="/images/bg/webp/bg-cb-1.webp"
        backgroundColor="#ffab00"
        btnTextColor="#000"
        btnTextHoverColor="#ffab00"
        backgroundHoverColor="#000"
      />
      {/* End Hero Section */}
      {/* End Especialistas Section */}
      <Spacing lg="200" md="80" />
      <Div className="container" id="next">
        <Div className="row reverse">
          <Div className="col-xl-5 col-lg-6 div-center  ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <VideoPlayer
                  src="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/8a854922a896bacf8f24539d63b5686a/manifest/video.m3u8"
                  poster="/images/thumbs/joel.jpg"
                />
              </Div>
            </Div>
          </Div>
          <Div className="col-xl-6 col-lg-6 offset-xl-1 div-center ">
            <SectionHeading
              title="Como ganhar dinheiro com franquias?"
              subtitle="Cheirin de um bom negócio!"
            >
              <Spacing lg="25" md="0" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Neste vídeo os sócios e criadores da marca, Wilton e Eduardo
                falam sobre como ganhar dinheiro com franquias.
              </p>
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Especialistas Section */}

      <Spacing lg="200" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-6 col-lg-6 div-center ">
            <SectionHeading
              id="faturar"
              title="UF Participações"
              subtitle="Cheirin de um bom negócio!"
            >
              <Spacing lg="25" md="0" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Neste episódio, Eduardo Schroeder e Wilton Bezerra, sócios da UF
                Participações, vão falar um pouco sobre suas carreiras e sobre
                os desafios e oportunidades de empreender no modelo de
                franquias. A UF é a responsável pela Cheirin Bão, CB To Go e
                Culinária Mineira, no ramo alimentício, e pela BeautyB e
                Depile-se, no ramo de estética, e foi a holding mais premiada
                pela Associação Brasileira de Franchising em 2022.
              </p>
            </SectionHeading>
          </Div>
          <Div className="col-xl-5 col-lg-6 offset-xl-1  div-center ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <VideoPlayer
                  src="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/6003c01e5e1c0117dfd9930979a50b2f/manifest/video.m3u8"
                  poster="/images/thumbs/dono-p-dono.jpg"
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Especialistas Section */}

      {/* End Especialistas Section */}
      <Spacing lg="200" md="80" />
      <Div className="container">
        <Div className="row reverse">
          <Div className="col-xl-5 col-lg-6 div-center  ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <VideoPlayer
                  src="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/b96ead455da3e7d74a2f2672663cf20f/manifest/video.m3u8"
                  poster="/images/thumbs/franquia-cast.jpg"
                />
              </Div>
            </Div>
          </Div>
          <Div className="col-xl-6 col-lg-6 offset-xl-1 div-center ">
            <SectionHeading
              title="Como montar uma cafeteria de sucesso?"
              subtitle="Cheirin de um bom negócio!"
            >
              <Spacing lg="25" md="0" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Neste vídeo os sócios e criadores da marca, Wilton e Eduardo
                falam sobre como montar uma franquia de sucesso.
              </p>
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Especialistas Section */}

      <Spacing lg="200" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-6 col-lg-6 div-center ">
            <SectionHeading
              id="faturar"
              title="KAISSER | Pessoas comuns podem alcançar a independência financeira"
              subtitle="Cheirin de um bom negócio!"
            >
              <Spacing lg="25" md="0" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Fundador da Kapsula e atualmente CEO da KPG S/A. Idealizador do
                maior evento de Marketing Digital do pais, Akard Experience. Aos
                17 anos, ele ganhou e perdeu seu primeiro milhão.
              </p>
            </SectionHeading>
          </Div>
          <Div className="col-xl-5 col-lg-6 offset-xl-1  div-center ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <VideoPlayer
                  src="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/27eb57cf2dfed1d71dd2d62618f34c9e/manifest/video.m3u8"
                  poster="/images/thumbs/kaisser.jpg"
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Especialistas Section */}

      {/* End Especialistas Section */}
      <Spacing lg="200" md="80" />
      <Div className="container">
        <Div className="row reverse">
          <Div className="col-xl-5 col-lg-6 div-center  ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <VideoPlayer
                  src="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/aa4e66971d3e5cb0497a11a2d34c4265/manifest/video.m3u8"
                  poster="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/aa4e66971d3e5cb0497a11a2d34c4265/thumbnails/thumbnail.jpg"
                />
              </Div>
            </Div>
          </Div>
          <Div className="col-xl-6 col-lg-6 offset-xl-1 div-center ">
            <SectionHeading
              title="Como construir uma marca de sucesso com Wilton Bezerra e Eduardo Schroeder"
              subtitle="Cheirin de um bom negócio!"
            >
              <Spacing lg="25" md="0" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Wilton e Eduardo são empreendedores e, juntos, fundaram a
                holding Universal Franchising, que administra o Empório Mineiro
                Cheirin Bão, maior rede de cafeterias do país e que hoje conta
                com mais de 800 unidades da franquia.
              </p>
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Especialistas Section */}

      <Spacing lg="200" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-6 col-lg-6 div-center ">
            <SectionHeading
              id="faturar"
              title="É possível faturar R$150 mil reais com uma franquia da Cheirin Bão?"
              subtitle="Cheirin de um bom negócio!"
            >
              <Spacing lg="25" md="0" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Neste vídeo, respondendo algumas perguntas, os sócios e
                criadores da marca, Wilton e Eduardo falam se é possível faturar
                R$ 150 mil reais mensais com uma franquia da Cheirin Bão, e
                porque isso é possível.
              </p>
            </SectionHeading>
          </Div>
          <Div className="col-xl-5 col-lg-6 offset-xl-1  div-center ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <VideoPlayer
                  src="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/cb7d8fbda23ac9c5514627adec49ebb3/manifest/video.m3u8"
                  poster="/images/thumbs/150mil.jpg"
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Especialistas Section */}

      <Spacing lg="200" md="80" />
      <Div className="container">
        <Div className="row reverse">
          <Div className="col-xl-5 col-lg-6 div-center  ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <VideoPlayer
                  src="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/7949386d8fe513437174dd81e56dde51/manifest/video.m3u8"
                  poster="/images/thumbs/sociedade.jpg"
                />
              </Div>
            </Div>
          </Div>
          <Div className="col-xl-6 col-lg-6 offset-xl-1 div-center ">
            <SectionHeading
              title="Como construir uma marca de sucesso com Wilton Bezerra e Eduardo Schroeder"
              subtitle="Cheirin de um bom negócio!"
            >
              <Spacing lg="25" md="0" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Dando continuidade ao nosso quadro de perguntas e respostas,
                trazemos um assunto que é muito perguntado para nós, que é sobre
                sociedade. Respondemos algumas perguntas e entregamos várias
                dicas que pode servir para o seu negócio.
              </p>
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Especialistas Section */}

      {/* Start MovingText Section */}
      <Spacing lg="125" md="70" />
      <MovingText text="Eduardo Schroeder" />
      <Spacing lg="105" md="70" />
      {/* End MovingText Section */}

      {/*
      <Div className="container">
        <Cta
          title="Um de nossos consultores entrará em contato com você para mais informações!"
          subtitle="Fique atento ao seu email e ao seu WhatsApp, nosso canal de comunicação é feita por ambos os métodos"
          btnText="Baixar apresentação completa"
          btnText2="Acessar grupo do WhatsApp"
          btnLink={buttonLink}
          btnLink2={buttonLink2}
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
       End CTA Section */}
    </>
  );
}
