import React from "react";
import Div from "../Div";
import MenuWidget from "../Widget/MenuWidget";
import Spacing from "../Spacing";

import "./footer.scss";

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  const copyrightLinks = [
    {
      title: "Termos de Uso",
      href: "https://universalfranchising.com.br/termos/",
    },
    {
      title: "Política de Privacidade",
      href: "https://universalfranchising.com.br/politicadeprivacidade/",
    },
  ];

  return (
    <footer className="cs-footer">
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="selos">
            <Div className="selo">
              <img
                class="img-fluid"
                alt="PEGN"
                title="PEGN"
                src="/images/selos/pegn.png"
              />
            </Div>
          </Div>
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">
              Copyright © 2023 | UF Gestão de Marcas e Patentes LTDA | CNPJ:
              32.071.571/0001-03
            </Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=" cs-style2" />
          </Div>
        </Div>
      </Div>
    </footer>
  );
}
