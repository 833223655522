import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import Hotjar from "@hotjar/browser";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "./scss/index.scss";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-WBC96TB",
};

const siteId = 3553306;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
