import { Route, Routes } from "react-router-dom";
import ErrorPage from "./components/Pages/ErrorPage";
import Layout from "./components/Layout";
import ScrollToTop from "./components/ScrollToTop";
import Videos from "./components/Pages/Videos";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Videos />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
